import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Banner from "../components/banner/banner";
import Dayjest from "../components/dayjest/dayjest";
import Footer from "../components/footer/Footer";
import Header, { Base_Url } from "../components/header/Header";
import NewsEvent from "../components/NewsEvent/NewsEvent";
import { message } from 'antd';

const Category = () => {
  const params = useParams();
  const [category, setCategory] = useState([]);

  const [page, setPage] = useState(1)

  const Click = () => {
    setPage(page + 1);
  };
  const [messageApi, contextHolder] = message.useMessage();

  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'Yangi habarlar topilmadi',
    });
  };
  const uniqueEventSet = new Set();

  useEffect(() => {
    axios.get(`${Base_Url}category/${params.slug}/?page=${page}`)
      .then(SearchThen => {
        // Filter out duplicates and add only unique items to the set
        SearchThen?.data?.results.forEach(item => {
          if (!uniqueEventSet.has(item.id)) {
            uniqueEventSet.add(item.id);
            setCategory(prevData => [...prevData, item]);
          }
        });
      })
      .catch(not => {
        error();
      });
  }, [params.slug,page]);

  let data = {
    news_single: params?.id,
    dayjest_title: "Hafta Dayjesti",
  };

  return (
    <>
    {contextHolder}
      <Header setPage={setPage}/>
      <NewsEvent title={params.slug} content={category} Click={Click}/>
      <Banner/>
      <Dayjest dayjest_title={data?.dayjest_title} />
      <Footer setPage={setPage}/>
    </>
  );
};

export default Category;
